<template>
  <base-popup
    v-if="data"
    :title="'Инструкция. ' + data.Name"
    :value="value"
    @input="$emit('input', $event)"
    :icon="'medexpert-doc'"
    :color="color"
    hide-save
  >
    <word-press-viewer
      :postId="data.WebId"
      type="instruction"
      @getData="complete()"
    />
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";
import WordPressViewer from "@/components/WordPress/WordPressViewer.vue";
import tourApi from "../api/tourApi";

export default {
  components: { BasePopup, WordPressViewer },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
  methods: {
    complete() {
      tourApi.help.doc.complete(this.data.Id);
    },
  },
};
</script>
